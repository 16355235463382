<style lang="less" scoped>
	.content {
		padding: 76px 285px;
		background: #ffffff;
		display: flex;

		.informations {
			width: 862px;
			// margin: 0 auto;
			margin-right: 120px;
			.title {
				font-size: 28px;
				font-weight: bold;
				text-align: left;
			}

			.time {
				display: flex;
				align-items: center;
				font-size: 18px;
				color: #666666;
				padding-top: 32px;

				div {
					padding: 0 26px;
				}

				div:nth-child(1) {
					padding-right: 26px;
					border-right: 1px solid #666666;
				}

			}

			.text {
				text-align: left;
				width: 100%;
				display: inline-block;
				overflow: hidden;
				word-wrap: break-word;
				white-space: pre-wrap;
				font-size: 20px;
				margin-top: 30px;

				/deep/ img {
					width: 100% !important;
					height: 100% !important;
				}

			}
		}

		.right {
			width: 381px;

			.right_box {
				text-align: left;
				cursor: pointer;
				.icon_image {
					width: 381px;
					height: 210px;
				}
				.right_title{
					font-size: 22px;
					line-height: 40px;
					margin: 17px 0 36px 0;
					font-weight: bold;
				}
			}
		}
		.big_box{
			width: 1360px;
			margin: 0 auto;
			display: flex;
		}
	}
</style>
<template>
	<div class="content">
		<div class="big_box">
		<div class="informations">
			<div class="title">{{obj.ne_title}}</div>

			<div class="time">
				<div style="padding-left: 0;">{{$t('information.time')}}:{{obj.create_time}}</div>
				<div>{{$t('information.source')}}:{{obj.ne_source}}</div>

				<!-- <div>{{$t('information.journalist')}}</div>
				<div>{{$t('information.compile')}}</div> -->
			</div>
			<div class="text" v-html="obj.ne_content">

			</div>
		</div>
		<div class="right">
			<div v-for="(item,index) in contentList" :key="index" class="right_box" @click="id=item.id;indormationDetail()">
				<div class="icon_image">
					<img :src="item.ne_img" />
				</div>
				<p class="right_title text_2_size">
					{{item.ne_title}}
				</p>
			</div>
		</div>
		</div>
	</div>
</template>

<script>
	export default {
		components: {

		},
		data() {
			return {
				id:this.$route.query.id,
				obj: {

				},
				contentList: []
			}
		},
		mounted() {
			this.indormationDetail()
			this.indormationList()
		},
		methods: {
			async indormationList() {
				try {
					let _this = this
					let params = {
						page: 1,
						type: 0
					}
					let res = await this.$axios.get('news/index', {
						params: params
					});
					console.log(res)
					if (res.code == 1) {
						this.contentList = res.data
						// this.$router.push('personalCenter')
					}
				} catch (error) {
					console.log(error);
				}
			},
			async indormationDetail() {
				try {
					let _this = this
					let data = {
						id: _this.id
					}
					let res = await this.$axios.get('news/detail', {
						params: data
					});
					console.log(res)
					if (res.code == 1) {
						this.obj = res.data
						var content = this.obj.ne_content
						const regex = new RegExp('<img', 'gi')
						content = content.replace(regex, `<img style="max-width: 100%; height: auto"`)
						this.obj.ne_content = content
						// this.$refs.text.innerHTML = this.obj.ne_content
						// this.$router.push('personalCenter')
					}
				} catch (error) {
					console.log(error);
				}
			},
		}
	}
</script>
